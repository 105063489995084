<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Passenger
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Passengers"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="customers"
          :options.sync="options"
          :server-items-length="totalRecords"
          :loading="loading"
          :items-per-page="5"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
          show-expand
          single-expand
          :expanded.sync="expanded"
          item-key="customerId"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="CustomerForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.firstName"
                            label="First Name"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.middleName"
                            label="Middle Name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.lastName"
                            label="Last Name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.dateOfBirth"
                            label="Date Of Birth"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.telephone"
                            label="Telephone"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.mobile"
                            label="Mobile"
                          />
                        </v-col>
                        <!-- <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.profilePhoto"
                            label="Profile Photo Link"
                          />
                        </v-col> -->
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="primary pa-1">
              <v-data-table
                :headers="job_headers"
                :items="item.jobs"
                :items-per-page="5"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    :color="getStatus(item.status).color"
                    text-color="white"
                    v-if="getStatus(item.status)"
                  >
                    {{ getStatus(item.status).text }}</v-chip
                  >
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  <div>{{ formatDate(item.date) }}</div>
                </template>
                <template v-slot:[`item.pickup.pickupTime`]="{ item }">
                  <div v-if="item.pickup">
                    {{ formatDate(item.pickup.pickupTime) }}
                  </div>
                </template>
                <template v-slot:[`item.dropOff.dropOffTime`]="{ item }">
                  <div v-if="item.dropOff">
                    {{ formatDate(item.dropOff.dropOffTime) }}
                  </div>
                </template>
              </v-data-table>
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import moment from "moment";
export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    options: {},
    totalRecords: 0,
    loading: false,
    headers: [
      {
        text: "Image",
        align: "start",
        value: "profile.profilePhoto",
        sortable: false,
      },
      { text: "First Name", value: "profile.firstName" },
      { text: "Middle Name", align: "start", value: "profile.middleName" },
      { text: "Last Name", value: "profile.lastName" },
      { text: "Date Of Birth", value: "profile.dateOfBirth" },
      { text: "Telephone", value: "profile.telephone" },
      { text: "Mobile", value: "profile.mobile" },
      { text: "Email", value: "email" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    job_headers: [
      {
        text: "Booking ID",
        align: "start",
        value: "id",
      },
      { text: "Status", align: "center", value: "status" },
      { text: "From", value: "fromCity" },
      { text: "To", value: "toCity" },
      { text: "Driver", value: "driver.user.userName" },
      { text: "Vehicle", value: "vehicle.vehicleNo" },
      { text: "Created at", value: "date" },
      { text: "Pickup time", value: "pickup.pickupTime" },
      { text: "Picked up time", value: "dropOff.dropOffTime" },
    ],
    customers: [],
    expanded: [],
    editedIndex: -1,
    editedItem: {
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      telephone: "",
      mobile: "",
      profilePhoto: "",
      email: "",
    },
    defaultItem: {
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      telephone: "",
      mobile: "",
      profilePhoto: "",
      email: "",
    },
    search: undefined,
    searchTimmer: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Passenger" : "Edit Passenger";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getAllCustomers("");
      },
      deep: true,
    },
    search: {
      handler(val) {
        clearTimeout(this.searchTimmer);
        this.searchTimmer = setTimeout(() => {
          this.getAllCustomers(val);
        }, 1500);
      },
    },
  },

  created() {},

  methods: {
    getAllCustomers(search) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        skip: itemsPerPage * (page - 1),
        take: itemsPerPage,
        searchValue: search,
      };

      this.$http
        .post("/Customer/GetAllBySearch", data)
        .then((response) => {
          this.loading = false;
          this.totalRecords = response.data.total;
          this.customers = response.data.customers;
        })
        .catch(() => {});
    },

    editItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem.customerId = item.customerId;
      this.editedItem.firstName = item.profile.firstName;
      this.editedItem.middleName = item.profile.middleName;
      this.editedItem.lastName = item.profile.lastName;
      this.editedItem.dateOfBirth = item.profile.dateOfBirth;
      this.editedItem.telephone = item.profile.telephone;
      this.editedItem.mobile = item.profile.mobile;
      this.editedItem.email = item.profile.email;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/Customer/${this.editedItem.customerId}`)
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
            this.customers.splice(index, 1);
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch(() => {});
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.CustomerForm.validate()) {
        if (this.editedIndex > -1) {
          let index = this.editedIndex;
          this.$http
            .put(`/Customer/${this.editedItem.customerId}`, this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.customers.splice(index, 1, response.data.data);
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            })
            .catch(() => {});
        } else {
          this.$http
            .post("/Customer", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
                this.customers.push(this.editedItem);
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            })
            .catch(() => {});
        }
        this.close();
      }
    },
    formatDate(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY, h:mm:ss a");
      } else {
        return "";
      }
    },
    getStatus(id) {
      switch (id) {
        case 1:
        case 2:
        case 3:
          return {
            id: id,
            color: "#eb8e55",
            text: "New",
          };
        case 4:
          return {
            id: id,
            color: "#f6c546",
            text: "Accepted",
          };
        case 5:
          return {
            id: id,
            color: "#f6f356",
            text: "ToPickup",
          };
        case 6:
          return {
            id: id,
            color: "#7ecd6e",
            text: "PickedUp",
          };
        case 7:
          return {
            id: id,
            color: "#77cdaf",
            text: "ToDropOff",
          };
        case 8:
          return {
            id: id,
            color: "#00ffa6",
            text: "DroppedOff",
          };
        default:
          return null;
      }
    },
  },
};
</script>
